body.light-mode {
    .goggles-dark-mode {
      display: none !important;
    }
}

body.dark-mode {
    .goggles {
      display: none !important;
    }
}

.goggles, .goggles-dark-mode {
    height: 70px;
}

@media only screen and (max-width: 992px) {
    .goggles, .goggles-dark-mode {
        height: 65px;
    }

    .race-car {
        left: -50px !important;
    }
}

.icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
    font-size: 10px;
}

.dark-mode-button {
    margin-left: -120px;
}

input[type='checkbox'].icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    vertical-align: 2px;
    outline: none;
    &:checked+label {
        left: 30px;
    }
    &:focus-visible {
        outline: solid 2px white;
    }
}

//edge does not support :before and :after on a checkbox element
_:-ms-lang(x),
.glasses {
    border: solid 1px black !important;
    border-radius: 0% !important;
    font-size: 10px;
}

_:-ms-lang(x),
.dark-mode-toggle:after {
    content: 'Shades';
    font-size: 14px;
}


/* Basic styles for SVGs */
.race-car {
    position: absolute; /* Ensure SVGs are positioned absolutely */
    left: -100px;
    width: 178px;
    height: 40px;
    transition: transform 0.5s ease-in-out; /* Smooth transition */
}

/* Initially position the original SVG and the new SVG */
.original {
    z-index: 2; /* Ensure it is above the new SVG initially */
}

.new {
    transform: translateX(100vw); /* Move the new SVG off-screen to the right initially */
    z-index: 1; /* Place it below the original SVG initially */
}

/* Move the original SVG out when the checkbox is checked */
#dm-toggle:checked ~ .dark-mode-button .original {
    transform: translateX(-100vw); /* Move the original SVG out of view to the left */
}

/* Move the new SVG into view when the checkbox is checked */
#dm-toggle:checked ~ .dark-mode-button .new {
    transform: translateX(0); /* Bring the new SVG into view */
}
  