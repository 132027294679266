@import '../../static/styles/base/colors';
@import '../../static/styles/base/variables';


body.dark-mode {
  .header__bg {
    position: absolute;
    top: 100px;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-image: linear-gradient(180deg, rgba(37, 40, 42, 0) 75%, #25282a), url('./race-track-3x2.png');
    // background-image: url('./race-track.png');
    background-size: cover;
    background-position-x: center;
  }
}

header {
  // position: relative;
  height: 800px;
  // overflow: hidden;
}

.header__bg {
  position: absolute;
  top: 100px;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-image: linear-gradient( to bottom, rgba($cultured,0) 75%, rgba($cultured,1) ), url('./race-track-3x2.png');
  // background-image: url('./race-track.png');
  background-size: cover;
  background-position-x: center;
}

@media (min-width: 200px) {
  .header__bg {
    background-image: url('./race-track.png');
  }
}

@media (min-width: 779px) {
  .header__bg {
    background-image: linear-gradient(0deg, rgba(37, 40, 42, 0) 50%, #25282a), url('./race-track-3x2.png');
  }
}



.header__bg__none {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
}


